import { Image, SkeletonCircle } from '@chakra-ui/react';
import React from 'react';

import { useAppContext } from 'lib/contexts/app';
import * as cookies from 'lib/cookies';
import useFetchProfileInfo from 'lib/hooks/useFetchProfileInfo';

interface Props {
  size: number;
  fallbackIconSize?: number;
}

const UserAvatar = ({ size }: Props) => {
  const appProps = useAppContext();
  const hasAuth = Boolean(
    cookies.get(cookies.NAMES.API_TOKEN, appProps.cookies),
  );
  const [ isImageLoadError, setImageLoadError ] = React.useState(false);
  const { data, isFetched } = useFetchProfileInfo();

  const sizeString = `${ size }px`;

  const handleImageLoadError = React.useCallback(() => {
    setImageLoadError(true);
  }, []);

  if (hasAuth && !isFetched) {
    return <SkeletonCircle h={ sizeString } w={ sizeString }/>;
  }

  return (
    <Image
      flexShrink={ 0 }
      src={ data?.avatar }
      alt={ `Profile picture of ${ data?.name || data?.nickname || '' }` }
      boxSize={ `${ size }px` }
      borderRadius="full"
      overflow="hidden"
      fallback={
        isImageLoadError || !data?.avatar ? (
          <svg
            width="21"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8 22.1H4.8C2.7 22.1 1 20.4 1 18.3C1 15.1 3.6 12.6 6.7 12.6H13.9C17.1 12.6 19.6 15.2 19.6 18.3C19.6 20.4 17.9 22.1 15.8 22.1Z"
              stroke="#004A78"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M10.3 9.8C12.7301 9.8 14.7 7.83005 14.7 5.4C14.7 2.96995 12.7301 1 10.3 1C7.86997 1 5.90002 2.96995 5.90002 5.4C5.90002 7.83005 7.86997 9.8 10.3 9.8Z"
              stroke="#004A78"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </svg>
        ) : undefined
      }
      onError={ handleImageLoadError }
    />
  );
};

export default React.memo(UserAvatar);
